import * as React from 'react';
import './modales.css';
import Modal from 'react-bootstrap/Modal';
import '../../pages/styles/index.scss';
import { UseTheme } from '../../context/ThemeContext';
import { Audio } from 'react-loader-spinner'

const LoadingModal = (props) => {
    const { theme } = UseTheme();

    return (
        <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            <div className="test">
            <Audio
                height="80"
                width="80"
                radius="9"
                color="#2A407E"
                ariaLabel="loading"
                wrapperStyle
                wrapperClass
            />
            </div>
            <Modal.Header>
                <Modal.Title className='confirm-text' style={theme === 'dark' ? {color: '#FFF'} : {color: '#2A407E'}}>
                    Processing Transaction
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex justify-content-center confirmation-text'>
                    <h6 className='confirmation-h6' style={theme === 'dark' ? {color: '#FFF'} : {color: '#52575C'}}>Please do not close this page</h6>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default LoadingModal