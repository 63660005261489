import * as React from 'react';
import './modales.css';
import Modal from 'react-bootstrap/Modal';
import '../../pages/styles/index.scss';
import { Button } from 'react-bootstrap';

const SuccessModal = (props) => {
    return (
        <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header>
                <Modal.Title className='success-buy-text m-auto'>
                    Transaction Success
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex justify-content-center confirmation-text'>
                    <h6 className='confirmation-buy-h6'>Your DTC was  {props.message}</h6>
                    {props.isTransfer && <div>{props.recipient}</div>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                    <Button className='background-buttons-home' id='success-button' onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default SuccessModal