import React, { useState, useEffect } from "react"
import MoreDTC from '../../../components/more-dtc/more-dtc'
import CardDetail from '../../../components/card-detail/card-detail'
import { Container, Row, Col, Card, Button, Form, Dropdown, DropdownButton } from "react-bootstrap"
import '../../styles/dtc-detail.css'
import Seo from '../../../components/seo/seo'
import { FaWallet } from "react-icons/fa";
import '../../styles/index.scss'
import { ethers } from 'ethers'
import { useMoralis } from "react-moralis";
import RingLoader from "react-spinners/RingLoader";
import { navigate } from "gatsby";
import { useDTCs } from '../../../context/DTCsContext';
import PurchaseModal from "../../../components/modales/purchaseModal"
import ConfirmModal from "../../../components/modales/confirmationModal"
import LoadingModal from "../../../components/modales/loadingModal"
import ReadMoreReact from 'read-more-react';
import { useUser } from "../../../context/UserContext"
import SuccessModal from "../../../components/modales/successModal"
import RejectedModal from "../../../components/modales/RejectedModal"

const DtcDetail = ({ pageContext }) => {
    const { mint_1155, create_supply, pay_with_card, get_dtc } = useDTCs();
    const { isInitialized, isAuthenticated } = useMoralis();
    const { connect, balance, maxGasFee } = useUser()
    const [gasFeeInMatic, setGasFeeInMatic] = useState()
    const [getDtc, setGetDtc] = useState()
    const [modalShow, setModalShow] = useState(false);
    const [amount, setAmount] = useState(1)
    const [dropdownOn, setDropdownOn] = useState(true)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [maticBalance, setMaticBalance] = useState()
    const [insufficientBalance, setInsufficientBalance] = useState(0);
    const [rejectedModal, setRejectedModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    let [loading, setLoading] = useState(false);

    useEffect(() => {
        const data = pageContext?.data;
        get_dtc({ _moralisId: data?.id }).then((response) => setGetDtc(response))
    }, [isInitialized, pageContext.data]);

    // setear el estado de visibilidad del dropdown
    const showDropdown = (state) => {
        setDropdownOn(state)
    }

    const handleClick = () => {
        navigate(-1)
    };

    let successUrl = `${window.location.origin}/success/`;
    let cancelUrl = `${window.location.origin}/`;

    const buyDTC = async (_amount, _dtc) => {
        setModalLoading(true)
        setShowConfirmationModal(false)
        if (!_amount || _amount < 0 || _amount !== Math.floor(_amount)) return

        // nuevo commit
        try {
            if (_dtc.attributes.state === 3) {
                // @params: tokenId, collectionAddress, _amount, moralisId, price
                const mintResponse = await create_supply(_dtc.attributes.tokenId, _dtc.attributes.collectionAddress, _amount, _dtc.id, () => setRejectedModal(true))
                if (mintResponse.success) setSuccessModal(true)
                if (!mintResponse.success) {
                    setModalMessage(mintResponse.error)
                    setRejectedModal(true)
                }
            } else {
                // @params: json, creator, timestamp, collectionAddress, signature, moralisId, _amount, maxSupply, price
                const mintResponse = await mint_1155({
                    _json: _dtc.attributes.json,
                    _creator: _dtc.attributes.creator,
                    _timestamp: _dtc.attributes.timestamp,
                    _collectionAddress: _dtc.attributes.collectionAddress,
                    _signature: _dtc.attributes.signature,
                    _moralisId: _dtc.id,
                    _amount: _amount,
                    // _price: valueString,
                    _maxSupply: _dtc.attributes.totalSupply ? _dtc.attributes.totalSupply : 65535,
                });
                if (mintResponse.success) setSuccessModal(true)
                if (!mintResponse.success) {
                    setModalMessage(mintResponse.error)
                    setRejectedModal(true)
                }
            }
            setModalLoading(false)
        } catch (error) {
            console.error(error);
        }
    };

    const checkIfConnected = async () => {
        setLoading(true)
        try {
            if (!isAuthenticated) {
                const userResponse = await connect();
                if (!userResponse) {
                    console.error('Account not connected');
                    return
                }
            }
            setModalShow(true)
            setLoading(false)
            return
        } catch (error) {
            console.error(error);
        }
    }

    const payWithCard = async (_amount, _dtc) => {
        if (!_amount || _amount < 0 || _amount !== Math.floor(_amount)) return

        try {
            if (_dtc.attributes.state === 3) {
                const response = await pay_with_card({
                    _moralisId: _dtc.id,
                    _tokenAmount: _amount,
                    _tokenId: _dtc.attributes.tokenId,
                    _successUrl: successUrl,
                    _cancelUrl: cancelUrl,
                });
                localStorage.setItem('lastURL', 'success');
                window.location.replace(response.url);
            } else {
                const response = await pay_with_card({
                    _moralisId: _dtc.id,
                    _tokenAmount: _amount,
                    _tokenId: 65535,
                    _successUrl: successUrl,
                    _cancelUrl: cancelUrl,
                });
                console.log('response => state != 3', response)
                localStorage.setItem('lastURL', 'success');
                window.location.replace(response.url);
            }
        } catch (error) {
            console.error(error);
        }

    };

    //conseguir el precio del gas en MATIC
    useEffect(() => {
        const getGasFee = async () => {
            try {
                const gasFee = await maxGasFee(getDtc, amount)
                setGasFeeInMatic(gasFee)
            } catch (error) {
                console.error(error)
            }
        }
        getGasFee();
    }, [balance])

    if (!getDtc || loading) {
        return (
            <Container className="loader">
                <RingLoader color="#2a4379" />
            </Container>
        )
    }
    else if (getDtc && 'attributes' in getDtc) {
        return (
            <>
                <PurchaseModal show={modalShow} onHide={() => setModalShow(false)} showConfirmationModal={() => { setShowConfirmationModal(true); setModalShow(false) }} payWithCard={() => payWithCard(amount, getDtc)} />
                <ConfirmModal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)} dtcName={getDtc.attributes?.name} payWithCrypto={() => buyDTC(amount, getDtc)} dtcAmount={amount} dtcPrice={ethers.utils.formatEther(getDtc.attributes?.price)} balance={insufficientBalance} gasFees={gasFeeInMatic} currentBalance={maticBalance} dtcPriceXAmount={ethers.utils.formatEther(getDtc.attributes?.price) * amount} /*onHideNoBalance={() => setFundsModalShow(false)}*/ />
                <LoadingModal show={modalLoading} />
                <SuccessModal show={successModal} onHide={() => setSuccessModal(false)} message="succesfully purchased." />
                <RejectedModal show={rejectedModal} message={modalMessage} onHide={() => setRejectedModal(false)} />

                <div className="background-theme">
                    <Container className="mt-5">
                        <Row className="dtc-detail">
                            {/* d-none-min-992 */}
                            <Col className="d-none-max-991 col-video" lg={5} >
                                <CardDetail src={getDtc.attributes?.image} />
                            </Col>

                            <Col xs={12} lg={6} className='spacing'>
                                <div className="go-back" onClick={handleClick} onKeyDown={handleClick} role="presentation">{'<'} Go Back</div>
                                <div className="d-flex-mobile-dtc">
                                    <div className="mobile-description">
                                        <h5>Collection Address: {getDtc?.attributes?.collectionAddress}</h5>
                                        <h1>{getDtc.attributes?.name}</h1>
                                        <ReadMoreReact max={400} text={getDtc.attributes?.description} />
                                        <div>
                                            <div className="d-flex my-3">
                                                <h3 className="mb-0">{ethers.utils.formatEther(getDtc.attributes?.price)} USD</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mobile-photo d-none-min-992">
                                        <CardDetail src={getDtc.attributes?.image} />
                                    </div>
                                </div>
                                <div className="d-flex my-2 buy-dtc-div">
                                    <Button className="background-buttons-home py-3 px-4"
                                        /*disabled={(!amount || amount < 0 || amount !== Math.floor(amount))}*/
                                        onClick={() => checkIfConnected()}><FaWallet className="mx-1" />Buy Now</Button>
                                    <Col >
                                        <h6 className="dtc-amount-text">Amount of DTC</h6>
                                        {dropdownOn ?
                                            <DropdownButton id="dropdown-basic-button" title={amount.toString()}>
                                                <Dropdown.Item onClick={() => setAmount(1)}>1</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setAmount(2)}>2</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setAmount(3)}>3</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setAmount(4)}>4</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setAmount(5)}>5</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setAmount(6)}>6</Dropdown.Item>
                                                <Dropdown.Item onClick={() => showDropdown(false)}>Manual Select</Dropdown.Item>
                                            </DropdownButton>
                                            :
                                            <>
                                                <Form.Control type="number" min={1} value={Math.abs(amount)} className="mx-2 w-25 form-nfts" placeholder="Amount to buy" onChange={(e) => setAmount(Number(e.target.value))} />
                                            </>
                                        }
                                    </Col>
                                </div>
                                <div className="comments-under-btn">You need to connect your wallet to buy an DTC</div>
                                <Card className="mt-2 border-out cards-collections background-theme">
                                    <Card.Body>
                                        {
                                            !!getDtc && getDtc.attributes.jsonObj?.attributes?.map((dtc, index) =>
                                            (dtc.trait_type === '' ? (null) : (
                                                <div key={index}>
                                                    <h6>{dtc.trait_type}</h6>
                                                    <span>{dtc.value}</span>
                                                </div>
                                            )
                                            )
                                            )
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <MoreDTC collection={getDtc.attributes?.collection} collectionAddress={getDtc.attributes?.collectionAddress} dtcToExclude={getDtc.id} artist={""} />
                    </Container>
                </div>
            </>
        )
    }
    else {
        return (
            <Container className="loader">
                <RingLoader color="#2a4379" />
            </Container>
        )
    }
}

export default DtcDetail

export const Head = ({ pageContext }) => (
    <Seo title={pageContext.data?.attributes?.name}
        description="Collect • Play • Trade"
        image="https://www.datocms-assets.com/80384/1664752762-dtc-logo-2021.png?auto=format"
    />
)