import React, { useState, useEffect } from "react"
import './modales.css';
import Modal from 'react-bootstrap/Modal';
import '../../pages/styles/index.scss';
import { Button } from 'react-bootstrap';

const RejectedModal = (props) => {
    const [message, setMessage] = useState('')

    useEffect(() => {
        switch (props.message) {
            case 'insufficient funds for gas * price + value':
                setMessage('Insufficient funds for the DTC purchase.')
                break;
            default:
                setMessage('Error in the DTC purchase.')
                break;
        }
    }, [props])

    return (
        <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header>
                <Modal.Title className='success-buy-text m-auto'>
                    Transaction Failed
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='d-flex justify-content-center'>
                <div className='confirmation-text insufficient-funds-modal'>
                    {/* <h6 className='confirmation-buy-h6'>User rejected transaction.</h6> */}
                    <h6 className='confirmation-buy-h6'>{message}</h6>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className='background-buttons-home' id='success-button' onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default RejectedModal