import * as React from 'react';
import './modales.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Row, Col } from 'react-bootstrap'
import '../../pages/styles/index.scss';
import applePayLight from '../../assets/applePayLight.png';
import amex from '../../assets/amex.png';
import googlePayLight from '../../assets/googlePayLight.png';
import googlePayDark from '../../assets/googlePayDark.png';
import applePayDark from '../../assets/applePayDark.png';
import visaLogo from '../../assets/visaLogo.png';
import visaLogoDark from '../../assets/visaLogoDark.png';
import mastercardLogo from '../../assets/mastercardLogo.png';
import polygonLogo from '../../assets/polygonLogo.png';
import polygonLogoDark from '../../assets/polygonLogoDark.png';
import { UseTheme } from '../../context/ThemeContext';

const PurchaseModal = (props) => {
    const { theme } = UseTheme();

    return (
        <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" style={theme === 'dark' ? {color: '#FFF'} : {color: '#2A407E'}}>
                    Payment Method
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='mt-3'>
                    <Col>
                        <Row className="payment-text mb-1" style={theme === 'dark' ? {color: '#FFF'} : {color: '#2A407E'}}>Crypto Currency</Row>
                        <Row>
                            <Button className='me-3' id={theme === 'dark' ? 'pay-with-crypto' : 'pay-with-crypto-dark'} style={theme === 'dark' ? {border: '1px solid #FFF'} : {border: '1px solid #2A407E'}} onClick={props.showConfirmationModal}>
                                {theme === 'dark' ? <img src={polygonLogoDark} width={180} className='polygon-logo-dark' alt="polygon-logo-dark" /> : <img src={polygonLogo} width={180} className='polygon-logo' alt="polygon-logo" />}
                            </Button>
                        </Row>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <Row className="payment-text mb-1" style={theme === 'dark' ? {color: '#FFF'} : {color: '#2A407E'}}>Credit Card</Row>
                        <Row>
                            <Button className='me-3' id={theme === 'dark' ? 'pay-with-credit-card' : 'pay-with-credit-card-dark'} style={theme === 'dark' ? {border: '1px solid #FFF'} : {border: '1px solid #2A407E'}} onClick={props.payWithCard}>
                                <img alt="backgroundDark" src={theme === 'dark' ?  visaLogo : visaLogoDark} />
                                <img alt="backgroundDark" src={mastercardLogo} />
                                <img alt="backgroundDark" src={amex} />
                                <img alt="backgroundDark" src={theme === 'dark' ?  applePayLight : applePayDark} />
                                <img alt="backgroundDark" src={theme === 'dark' ?  googlePayLight : googlePayDark} />
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}

export default PurchaseModal