import Card from 'react-bootstrap/Card';
import * as React from "react"
import './card-detail.css'
import ReactPlayer from 'react-player'

const CardDetail = ({ src }) => {
    const [video, setVideo] = React.useState(false)

    const isVideo = async () => {
        var req = await fetch(src, { method: 'HEAD' });
        if (req.headers.get('content-type') === 'video/mp4') {
            setVideo(true);

        } else {
            setVideo(false)
        }
    }
    React.useEffect(() => {
        isVideo();
    }, [src])

    return (
        <figure className="card-figure-top border-radius-2">
            {!video ?
                (<Card.Img variant="top" alt="Logo" src={src} />)
                :
                (
                    <>
                        <ReactPlayer
                            url={src}
                            muted={true}
                            loop={true}
                            playing={true}
                            volume={0.01}
                            controls={true}
                        />
                    </>

                )
            }
        </figure>
    );
}
export default CardDetail;
