// import '../popular-dtc.css'
import React, { useState, useEffect } from "react"
import Dtc from '../dtc/dtc'
import { Row, Col } from "react-bootstrap"
import { useMoralis } from "react-moralis";

import { useDTCs } from '../../context/DTCsContext';

const MoreDtc = ({ collectionAddress, dtcToExclude }) => {

    const { isInitialized } = useMoralis();
    const [ getDtcs, setGetDtcs ] = useState();

    const { get_dtcs } = useDTCs();

    useEffect(() => {
        get_dtcs({ _collectionAddress: collectionAddress, _exclude: dtcToExclude, _state: [2, 3] }).then((response) => setGetDtcs(response))
    }, [isInitialized, collectionAddress, dtcToExclude]);

    return (
        <div className="pt-5">
            {collectionAddress === undefined ? (<h3 className="mb-4">View more DTC</h3>) : (<h3 className="mb-4">More from Featured Collection</h3>)}
            <Row className="mb-4">
                {
                    getDtcs?.slice(0, 3).map((dtc, index) =>
                    <Col className="mb-3 ps-4 pe-4" xs={12} sm={12} md={4} key={index}>
                            <Dtc src={dtc.attributes.image} name={dtc.attributes.name} collection={dtc.attributes.collection} price={dtc.attributes.price} sendTo={"/dtc-detail/" + dtc.id}/>
                        </Col>
                    )
                }
            </Row>
        </div>

    );
}
export default MoreDtc;