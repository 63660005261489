import React from "react"
import './modales.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../../pages/styles/index.scss';
import { UseTheme } from '../../context/ThemeContext';

const ConfirmModal = (props) => {
    const { theme } = UseTheme();
    //const [fundsModalShow, setFundsModalShow] = useState(true);

    return (
        <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            {(props.balance) === 0 ?
            <><Modal.Header closeButton>
                <Modal.Title className='confirm-text' style={theme === 'dark' ? {color: '#FFF'} : {color: '#2A407E'}}>
                    Please Confirm Transaction
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex justify-content-center confirmation-text'>
                    <h6 className='confirmation-h6' style={theme === 'dark' ? {color: '#FFF'} : {color: '#52575C'}}>DTC Name: </h6>
                    <div style={theme === 'dark' ? {color: '#FFF'} : {color: '#2A407E'}} className='confirmation-div'>{props.dtcName}</div>
                </div>
                <div className='d-flex justify-content-center confirmation-text'>
                    <h6 className='confirmation-h6' style={theme === 'dark' ? {color: '#FFF'} : {color: '#52575C'}}>Amount of DTC: </h6>
                    <div style={theme === 'dark' ? {color: '#FFF'} : {color: '#2A407E'}} className='confirmation-div'>{props.dtcAmount}</div>
                </div>
                <div className='d-flex justify-content-center confirmation-text'>
                    <h6 className='confirmation-h6' style={theme === 'dark' ? {color: '#FFF'} : {color: '#52575C'}}>Price: </h6>
                    <div style={theme === 'dark' ? {color: '#FFF'} : {color: '#2A407E'}} className='confirmation-div'>{(Number(props.dtcPrice) * Number(props.dtcAmount)).toString() } USD</div>
                </div>
            </Modal.Body>
            <div className='d-flex justify-content-center'>
                <Button className='confirm-modal-buttons confirm-no' onClick={props.onHide} style={theme === 'dark' ? {border: '1px solid #FFF', color: '#FFF'} : {border: '1px solid #2A407E', color: '#2A407E'}}>No</Button>
                <Button className='confirm-modal-buttons confirm-yes' onClick={props.payWithCrypto} style={{backgroundColor: '#2A407E', color: '#FFF', border: '1px solid #2A407E'}}>Yes</Button>
            </div></>
            : (props.balance) === 1 ?
            <>{/*<NoBalanceModal show={fundsModalShow} currentBalance={props.currentBalance} onHide={() => setFundsModalShow(false)} dtcPrice={props.dtcPriceXAmount} />*/}
            <Modal.Header>
                <Modal.Title className='success-buy-text m-auto'>
                    Transaction Failed
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='d-flex justify-content-center'>
                <div className='confirmation-text insufficient-funds-modal'>
                    <h6 className='confirmation-buy-h6'>Insufficient balance.</h6>
                    <div>Needed: {props.dtcPriceXAmount} MATIC</div>
                    <div>Current: {props.currentBalance} MATIC</div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                    <Button className='background-buttons-home' id='success-button' onClick={props.onHide}>Close</Button>
            </Modal.Footer></>
            :
            <>
                <Modal.Header>
                    <Modal.Title className='success-buy-text m-auto'>
                        Transaction Failed
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='d-flex justify-content-center'>
                    <div className='confirmation-text insufficient-funds-modal'>
                        <h6 className='confirmation-buy-h6'>Insufficient funds for gas fees.</h6>
                        <div>Gas Fees: {props.gasFees} MATIC</div>
                        <div>Current Balance: {props.currentBalance} MATIC</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='background-buttons-home' id='success-button' onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </>
            }
        </Modal>
    );
}

export default ConfirmModal